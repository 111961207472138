import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let LogInErrorModal = class LogInErrorModal extends AbstractModal {
    /**
     * Handles the `@click` event on the "log in" button.
     */
    onTryAgainBtnClick() {
        this.swapModal(EModal.LogIn);
    }
};
LogInErrorModal = __decorate([
    Component({ name: 'LogInErrorModal' })
], LogInErrorModal);
export { LogInErrorModal };
export default LogInErrorModal;
